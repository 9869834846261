// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Poppins-Light.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Poppins\"; /*Can be any text*/\n  src: local(\"Poppins\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\nbody {\n  font-family: \"Poppins\";\n}", "",{"version":3,"sources":["webpack://src/App.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA,EAAA,kBAAA;EACA,iFAAA;AACF;AASA;EACE,sBAAA;AAPF","sourcesContent":["@font-face {\n  font-family: \"Poppins\";   /*Can be any text*/\n  src: local(\"Poppins\"),\n    url(\"./fonts/Poppins-Light.ttf\") format(\"truetype\");\n}\n\n// @font-face {\n//   font-family: 'digitalMono';\n//   src: local('digitalMono'),\n//        url('./assets/fonts/digital-7 (mono).ttf') format('truetype'), /* Super Modern Browsers */\n// }\n\nbody {\n  font-family: \"Poppins\";\n  // background-color: black;\n  // overflow-x: hidden;\n\n  // font-family: digital;\n  // color: white;\n}\n\n\n// .container {\n//   max-width: 1400px;\n//   position: relative;\n//   padding: 0 60px;\n//   transform: translateX(-50%);\n//   left: 50%;\n// }\n\n// .notification-container {\n//   width: 600px;\n// }\n\n// @media (min-width: 0px) and (max-width: 768px) {\n//   .container {\n//     padding: 0 0px;\n//   }\n\n//   .notification-container {\n//     width: 80%;\n//   }\n// }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
