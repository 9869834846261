export const ROUTERS = {
    DASHBOARD: "/",
}

export const ENVS = {
    // Ethereum Mainnet
    // CONTRACT_ADDRESS: "0x699d0Efa91DB8Ba0128792B1231Ee8a478141491",
    // CHAIN_ID: "0x1",

    // Goerli Mainnet
    CONTRACT_ADDRESS: "0x57092c53cADfaaB18C2720fcae28A1bF33a47F36",
    CHAIN_ID: "0x5",

    // BSC Mainnet
    // CONTRACT_ADDRESS: "0xb86677C6c61284fC5C76b065A164F1C811A488E3",
    // CHAIN_ID: "0x38",

    NORMAL_VOTE_FEE: "0.0035",
    RUBY_TIRE_FEE: "0.5",
    DIAMOND_TIRE_FEE: "1",
    
    RUBY_TIRE_LIMIT: 250,
    DIAMOND_TIRE_LIMIT: 500,

    CMC_KEY: "a8dd2502-9e7c-4bf6-8cd2-29d2829e042a",

    CHARITY_ADDR: "0x63934E9D4251Bf82774dFE0f40468A1DC0e7e588",
    TREASURY_ADDR: "0x63934E9D4251Bf82774dFE0f40468A1DC0e7e588"
}